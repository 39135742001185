import React from "react";
import {
  makeStyles,
  Grid,
  Box,
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
} from "@material-ui/core";
import { LargeBody } from "helpers/text";
import { ExternalLink } from "helpers/Link";
import { donators } from "./donators";

// ----------------------------------------------------------------
//     各種コンポーネント
// ----------------------------------------------------------------

const useDonatorCardStyle = makeStyles((theme) => ({
  root: {
    // display: "block",
    textDecoration: "none",
    // wordWrap: "break-word",
    color: theme.palette.text.primary,
    maxWidth: 360,
  },
  icon: {
    width: "40px",
    height: "40px",
  },
}));

const DonatorCard: React.FC<{
  name: string;
  href: string;
  icon: string | undefined;
}> = ({ name, href, icon }) => {
  const classNames = useDonatorCardStyle();
  const content = (
    <CardHeader
      avatar={<Avatar className={classNames.icon} src={icon} />}
      title={<LargeBody>{name}</LargeBody>}
    />
  );

  return (
    <Grid item xs={12} sm={6} md={4} className={classNames.root}>
      <Card>
        {href ? (
          <CardActionArea component={ExternalLink} href={href}>
            {content}
          </CardActionArea>
        ) : (
          <div className={classNames.root}>{content}</div>
        )}
      </Card>
    </Grid>
  );
};

export const DonatorList: React.FC = () => {
  return (
    <Box mt={4} mx={2}>
      <Grid container spacing={2} justify="center">
        {donators.map((data, i) => (
          <DonatorCard
            key={i}
            name={data.name}
            href={data.href}
            icon={data.icon}
          ></DonatorCard>
        ))}
      </Grid>
    </Box>
  );
};

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------
