import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import { main, Layout } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { Title, LargeBody } from "helpers/text";
import { Section, TransparentSection } from "helpers/pages/index/common";
import TopSection from "helpers/pages/index/TopSection";
import { makeText, useLang } from "helpers/i18n";
import SessionCard, { AndroidSelectsCard } from "helpers/SessionCard";
import { sessions } from "helpers/sessions";
import jetpackPng from "../images/cfs-category/jetpack.png";
import SessionCard2 from "helpers/SessionCard2";
import { ExternalLink } from "helpers/Link";
import { DonatorList } from "helpers/pages/index/donators/DonatorList";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    about: (
      <>
        エンジニアが主役の Android カンファレンス DroidKaigi 2020 Lite を
        8/27〜8/29 にオンライン開催します。
        オンラインでのセッションの他、fireside chat やコードラボも行う予定です。
        <br />
        <br />
        本イベントはチャリティイベントで参加費は無料となっております。
        寄付窓口や事前通知などメールがとどく参加登録も受け付けておりますので How
        to join をご覧ください。
      </>
    ),
    codelab:
      "Android の最新技術やノウハウが学べるコードラボです。チューターとチャットで相談をしながら実践形式で学ぶ事ができます。",
    firesideChat:
      "DroidKaigi 2020 Lite のスピーカーやゲストを交えてより深掘りしたトークを行います。",
    howToJoin: (
      <>
        DroidKaigi 2020 Lite は 8/27〜8/29 に YouTube Live
        でオンライン配信を実施します。DroidKaigi
        チャンネルを登録してお待ち下さい。事前通知をご希望の場合は Doorkeeper
        のご登録をお願いします。
        <br />
        <br />
        イベントの参加は無料ですが、Doorkeeper
        にて寄付を受け付けております。詳しくは
        <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/110253?locale=ja">
          Doorkeeper のページ
        </ExternalLink>
        をご覧ください。
      </>
    ),
    timetable: (
      <>
        タイムテーブルは
        <ExternalLink href="https://docs.google.com/spreadsheets/d/1_re9TU--3uUTxSks3ewABnx2hPs0xy05MBk96EVlf0U/">
          こちら
        </ExternalLink>
        をご覧ください。
      </>
    ),
    codelabDetail: (
      <>
        Android の最新技術やノウハウが学べるコードラボです。チューターと Discord
        で相談をしながら実践形式で学ぶ事ができます。
        <br />
        <br />
        詳細は
        <ExternalLink href="https://docs.google.com/document/d/1e9wbx9JfgYBbKMYF8i_y5xgk7YbB0nUYO9XRy01lvg4/">
          こちら
        </ExternalLink>
        をご覧ください。
      </>
    ),
    donatorsDescription:
      "DroidKaigi 2020 Lite で寄付をいただいた方の一覧です。",
  },
  en: {
    about: (
      <>
        DroidKaigi 2020 Lite is a developer-first Android conference that will
        be held online on Aug 27 - 29. Join us with online sessions, fireside
        chats, and codelabs.
        <br />
        <br />
        DroidKaigi 2020 Lite is a charity event, so attendance is free. You can
        register in the How to Join section below, where you can make donations
        and get notified of updates on the event.
      </>
    ),
    codelab:
      "Codelabs will feature the latest and greatest of Android. Join us to put new features to use and talk with tutors over chat.",
    firesideChat:
      "Deep dives into Android topics with guests and DroidKaigi 2020 Lite speakers.",
    howToJoin: (
      <>
        The event will be streamed on Youtube Live on Aug 27 - 29. Be sure to
        subscribe to the DroidKaigi YouTube channel beforehand. For
        notifications, please register via Doorkeeper.
        <br />
        <br />
        Attendance is free, but we are accepting donations as well. For details,
        refer to{" "}
        <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/110253?locale=en">
          this Doorkeeper page
        </ExternalLink>
        .
      </>
    ),
    timetable: (
      <>
        Please check our{" "}
        <ExternalLink href="https://docs.google.com/spreadsheets/d/1_re9TU--3uUTxSks3ewABnx2hPs0xy05MBk96EVlf0U/">
          timetable
        </ExternalLink>
        .
      </>
    ),
    codelabDetail: (
      <>
        Codelabs will feature the latest and greatest of Android. Join us to put
        new features to use and talk with tutors over Discord.
        <br />
        <br />
        Please click{" "}
        <ExternalLink href="https://docs.google.com/document/d/1e9wbx9JfgYBbKMYF8i_y5xgk7YbB0nUYO9XRy01lvg4/">
          here
        </ExternalLink>{" "}
        for details.
      </>
    ),
    donatorsDescription: "Donors of DroidKaigi 2020 Lite.",
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const IndexMain: React.FC = () => {
  const lang = useLang();
  const text = useText();
  return (
    <Layout>
      <TopSection />
      <Section>
        <MainContainer maxWidth="md">
          <Title>What is DroidKaigi 2020 Lite?</Title>
          <Box
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 640 }}
          >
            <LargeBody xsAlignLeft>{text.about}</LargeBody>
          </Box>
        </MainContainer>
      </Section>
      <TransparentSection>
        <MainContainer maxWidth="lg">
          <Title xsAlignLeft={false}>Timetable</Title>
          <LargeBody align="center">{text.timetable}</LargeBody>
        </MainContainer>
      </TransparentSection>
      <TransparentSection>
        <MainContainer maxWidth="lg">
          <Title xsAlignLeft={false}>Sessions</Title>
          <Grid container spacing={3} justify="center">
            {sessions.slice(0, 7).map((s) => {
              return (
                <Grid key={s.title[lang]} item xs={12} sm={6} md={4} lg={3}>
                  <SessionCard
                    title={s.title[lang]}
                    speaker={s.speakers[0].fullName}
                    picture={s.speakers[0].profilePicture}
                    video={s.asset.videoUrl}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AndroidSelectsCard />
            </Grid>
          </Grid>
          <Box mt={12}>
            <Grid container justify="center">
              <Grid item xs={12} md={4} sm={6}>
                <SessionCard2
                  title={text.firesideChat}
                  speaker="Fireside Chat"
                  picture={jetpackPng}
                />
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </TransparentSection>
      <TransparentSection>
        <MainContainer maxWidth="lg">
          <Title xsAlignLeft={false}>Codelab</Title>
          <Box
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 640 }}
          >
            <Paper>
              <Box px={{ xs: 3, sm: 6 }} py={6}>
                <LargeBody>{text.codelabDetail}</LargeBody>
              </Box>
            </Paper>
          </Box>
        </MainContainer>
      </TransparentSection>
      <Section>
        <MainContainer maxWidth="md">
          <Title>How to Join?</Title>
          <Box
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 640 }}
          >
            <LargeBody>{text.howToJoin}</LargeBody>
          </Box>
        </MainContainer>
      </Section>
      <TransparentSection>
        <MainContainer>
          <Title>Special Thanks</Title>
          <LargeBody xsAlignLeft={true} align="center">
            {text.donatorsDescription}
          </LargeBody>
          <DonatorList />
        </MainContainer>
      </TransparentSection>
    </Layout>
  );
};

export default main(IndexMain);
