// 寄付をくださった方の一覧のスプレッドシートからコピペします
// 空行は無視されます。
/* eslint-disable */
const data = `
tetsukay	https://github.com/tetsukay
t-kashima	https://github.com/t-kashima
tarumzu	https://twitter.com/tarumzu
kirimin-chan	https://twitter.com/kirimin_chan
marshi	https://github.com/marshi
Yuki Nakakura	https://twitter.com/hiroaki_dev
Tamaki Hidetsugu	https://twitter.com/r_ralph_h
masaibar	https://twitter.com/masaibar
kk2170	https://github.com/kk2170
m.coder	https://twitter.com/_m_coder
kou_hon	https://twitter.com/kou_hon
そば屋	https://twitter.com/sobaya15
Wasabeef	https://twitter.com/wasabeef_jp
azukinohiroki	https://github.com/azukinohiroki
yoshida261	https://github.com/yoshida0261
ohashi	https://twitter.com/pg0084
OldBigBuddha	https://twitter.com/OldBigBuddha
Soichi Ikebe	https://github.com/Aqua-ix
@akai_t	https://twitter.com/akai_t
Panini	https://twitter.com/panini_ja
takathemax	https://twitter.com/takathemax
Tsuyoshi Uehara	https://twitter.com/uecchi
tomorrowkey	https://github.com/tomorrowkey
Saryong Kang	https://twitter.com/justfaceit_kt
fuuuuumin65	https://twitter.com/fuuuuumin65
k.yamamoto	https://twitter.com/kymmt24/
ogapants	https://twitter.com/ogapants
urotarou	
shogouki	https://twitter.com/ikeda_shogouki/
Shuichi Sato	https://github.com/syuchan1005
igreenwood	https://twitter.com/_igreenwood
Keima	https://github.com/keima
わさび	https://twitter.com/wasabi_9187
bina1204	https://twitter.com/bina1204
Aizawa Moyuru	https://twitter.com/MoyuruAizawa
dagezi	https://twitter.com/dagezi
ふじたく	https://twitter.com/magie_pooh
mochico	https://github.com/mochico
consomme	https://twitter.com/consomme72
kobaken	https://github.com/kobaken0029
tomoya_shibata	https://twitter.com/tomoya_shibata
kumamotone	https://twiter.com/kumamo_tone
Kishikawa Katsumi	https://github.com/kishikawakatsumi
	
cellfusion	https://github.com/cellfusion
koyama_kani_daisuki	https://github.com/nein37
a2kaido	https://twitter.com/a2kaido
hama	https://mobile.twitter.com/Naoyukisc2
mkikuchi	https://github.com/m-kikuchi777
yatatsu	https://twitter.com/tatsuyakit
tatsuhama	https://twitter.com/tatsuhama50
sada	https://twitter.com/sadashi_ota
RyuNen344	https://twitter.com/RyuNen344
Takahiro Shimokawa	https://twitter.com/androhi
shoheikawano	https://github.com/shoheikawano
henteko	https://twitter.com/henteko07
atsushieno	https://github.com/atsushieno
kugo	https://twitter.com/95kugo
Kazuki Nara	https://github.com/kazukinr
sumio_tym	https://twitter.com/sumio_tym
charden	https://github.com/charden
akihito-okada	https://github.com/akihito-okada
ysti	https://twitter.com/yst_i?s=09
Hiroshi Hashimoto	https://twitter.com/hi6484
安部　亨	
aki02	
citrous	https://github.com/citrous
Hiron	https://twitter.com/hironytic
Malvin Sutanto	https://twitter.com/MalvinSutanto
robo	https://twitter.com/cch_robo
ha_hagawa	https://twitter.com/ha_hagawa
Masatoshi Kubode	https://twitter.com/swiz_ard
Takanori Hoshi	
teshi	https://twitter.com/teshi04
laiso	https://twitter.com/laiso
katsummy	https://twitter.com/katsummy
kafumi	https://github.com/kafumi
HoNKoT	https://twitter.com/SYABU555
STAR_ZERO	https://twitter.com/STAR_ZERO
Gan0803	https://twitter.com/Gan0803
Hideharu Nagakura	https://github.com/hideharunagakura
mkeeda	https://twitter.com/mk_mkee
sekitoba	https://twitter.com/sekitoba
Hiroyasu Kuraya	https://github.com/HiroyasuKuraya
PM_Coffee	https://twitter.com/PM_Coffee
djyugg	https://github.com/djyugg
mattukouta	https://twitter.com/kota_2402
Gazyu	https://twitter.com/Gazyu
kassy_kz	https://twitter.com/kassy_kz
Takeshi Hagikura	https://twitter.com/thagikura
Kosuke Matsumura	https://twitter.com/kosuke_mtm
nyafunta	https://twitter.com/nyafunta9858
chibatching	https://github.com/chibatching
Hiroshi Kurokawa	https://twitter.com/hydrakecat
huin	https://twitter.com/huin
virifi	https://twitter.com/virifi
keidroid	https://twitter.com/keidroid
TomAndDev	https://github.com/tomandev-tiovita
syo-sa1982	https://github.com/syo-sa1982
rmakiyama	https://twitter.com/_rmakiyama
	https://github.com/kazkn
miyado	
TAKAHIRO	https://twitter.com/Takahiro_1984
@cattaka_net	https://twitter.com/cattaka_net
asmz	https://twitter.com/_asmz
Yagami Yuichi	https://github.com/sacred-sanctuary
kenken	https://twitter.com/tkhs0604
m_shige1979	https://twitter.com/m_shige1979
Sosuke Masui	https://github.com/esmasui
tsutou	https://github.com/Tsutou
Yasutaka Kawamoto	https://twitter.com/yasi_kawamoto
Naoki ISHII	https://twitter.com/IIInaIII
heisy	https://github.com/heisy3390
Tomoya Miwa	https://twitter.com/tomoya0x00
Koji Wakamiya	https://github.com/koji-1009
fu_nya	
Nozomi Takuma	https://twitter.com/fgfgtkm
s-takano	https://twitter.com/shinya_tk
@hiromoon_428	https://twitter.com/hiromoon_428
ndazmakorekiyo	https://github.com/kiyoshisaito
takenach	https://twitter.com/ctake0005
shiita0903	https://github.com/shiita0903
FORTE	https://twitter.com/FORTEgp05
HiroYUKI Seto	https://twitter.com/seto_hi
kuluna	https://github.com/kuluna
matsumo	https://twitter.com/sardinej
horie23	https://github.com/horie23
KazaKago	https://twitter.com/KazaKago
Katsuto Nakamigawa	https://github.com/knakamigawa
oboenikui	https://twitter.com/oboenikui
Shuzo	https://mobile.twitter.com/shuzo_create
nano2_aloerina	https://twitter.com/nano2_aloerina
blendthink	https://twitter.com/blendthink
YMatoi	https://github.com/YMatoi
nacatl	https://github.com/nacatl
Takuya Ohsawa	https://twitter.com/t_osawa_009
tounyubnn
muniere	https://github.com/muniere
Soma	https://twitter.com/Riberd_Puzzle1
AAkira	https://twitter.com/_a_akira
maru333	https://twitter.com/maru333
Fuji	https://github.com/FujiKinaga
Yoshiro MIHIRA	https://twitter.com/yoshiro_mihira
takahirom	https://twitter.com/new_runnable
ymshita	https://twitter.com/sushicollective
Kohei Yamamoto
Yusaku Tanaka	https://twitter.com/imsaku
	
	
Takuo Suzuki	https://twitter.com/taquo
nakamuuu	https://twitter.com/chickmuuu
el_metal_	https://twitter.com/el_metal_
tezooka	https://github.com/tezooka
j3iiifn	https://github.com/j3iiifn
chiiia12	https://github.com/chiiia12
suino	https://twitter.com/sn_f
Satoru Sasaki	https://github.com/caad1229
kitwtnb	https://twitter.com/_nabech
Hunachi	https://github.com/Hunachi
syarihu	https://twitter.com/syarihu
anz
yakan	https://twitter.com/yakan04601471
まーくん	https://twitter.com/macha1972
k_shinn	https://twitter.com/k_shinn
kateinoigakukun	https://github.com/kateinoigakukun
esugita	https://github.com/esugita
Nabe	https://github.com/NUmeroAndDev
Zenichi Amano	https://github.com/crow-misia
Shinya Nakajima	https://twitter.com/nakasho_dev
Atsushi Sakai	https://twitter.com/_atsushisakai
rnakano	https://github.com/rnakano
Naoki Yamamoto	https://twitter.com/mos1210

maru	https://twitter.com/marudroid
tfandkusu	https://twitter.com/tfandkusu
misadroid	https://twitter.com/misadroid
ochi	https://twitter.com/ochi_tw
mukaiyachidaiki	https://github.com/mukaiyachidaiki
akatsuki174	https://twitter.com/akatsuki174
ShimaneEngineer	https://twitter.com/ShimaneEngineer
Kenichi Kambara	https://twitter.com/korodroid
ke__kyukyun1828	https://mobile.twitter.com/ke__kyukyun1828
tjmmm	https://twitter.com/_tjmmm_
kokiko	https://avatars0.githubusercontent.com/u/11144241
fusuma	https://twitter.com/fusuma0325
ktomosada	https://twitter.com/tmp0830
shirai
Tatsuya Fujisaki	https://github.com/tatsuyafujisaki
aosa4054	https://twitter.com/aosa4054
hisakazu
kidasan	https://twitter.com/kidasan
yuyakaido	https://twitter.com/yuyakaido

yusuke	https://github.com/svartalfheim
Mizune	https://twitter.com/m1zyuk1
munetoshi
Yu Mogami
nash	https://twitter.com/nashcft
takuji31	takuji31
Satoshi Watanabe	http://twitter.com/sassy_watson
sobachanko	https://github.com/sobachanko
takechee	https://github.com/takechee
Takashi Suzuki
sugimotoak	https://github.com/sugimotoak
Masaru Horioka
kame0_0	https://mobile.twitter.com/kame0_0
`;
/* eslint-enable */

export class Donator {
  constructor(public readonly name: string, public readonly href: string) {}

  public get icon(): string | undefined {
    const pattern = /^https:\/\/github.com\/(.*)$/;
    const match = pattern.exec(this.href);
    if (match) {
      return `https://github.com/${match[1]}.png?size=128`;
    } else {
      return undefined;
    }
  }
}

export const donators = data
  .split("\n")
  .map((line) => {
    const pattern = /^([^\t]+)\t(http[^\s]+)?$/;
    const match = pattern.exec(line);
    if (!match) return [];
    else return [match[1], match[2]];
  })
  .filter((columns) => columns.length === 2)
  .map(([name, url]) => {
    return new Donator(name, url);
  });
