import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Grid,
  Avatar,
  Button,
} from "@material-ui/core";
import Link, { ExternalLink } from "helpers/Link";
import { Headline, Caption } from "helpers/text";
import YouTubeIcon from "@material-ui/icons/YouTube";

// ----------------------------------------------------------------
//     タイムテーブル/カード内
// ----------------------------------------------------------------

const useStyles = makeStyles(
  ({ palette, breakpoints, typography, spacing }) => ({
    frame: {
      height: "100%",
      minHeight: spacing(20),
      overflow: "hidden",
      [breakpoints.down("xs")]: {
        paddingBottom: spacing(1),
      },
      "&.timetable-lang": {
        borderTopLeftRadius: "8px",
      },
    },

    link: {
      display: "block",
      height: "100%",
    },

    topContent: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
    },

    header: {
      marginLeft: 0,
      lineHeight: "20px",
      textAlign: "right",
      [breakpoints.down("xs")]: {
        marginLeft: "0px",
        textAlign: "left",
      },
    },

    duration: {
      color: palette.text.secondary,
      verticalAlign: "top",
      fontSize: typography.caption.fontSize,
      [breakpoints.down("xs")]: {
        textAlign: "left",
        paddingLeft: "8px",
      },
    },

    title: {
      [breakpoints.up("sm")]: {
        minHeight: "7eM",
      },

      "& a": {
        color: "inherit",
      },
    },

    message: {
      marginTop: "0.25em",
      marginBottom: "0.25em",
    },
  })
);

// ----------------------------------------------------------------
//     レイアウト/タイムテーブルの項目
// ----------------------------------------------------------------

type SesscionCardProps = {
  title: string;
  speaker?: string;
  picture?: string;
  link?: string;
  video?: string;
  videoTitle?: string;
  onClick?: () => void;
};

/**
 * セッションをテーブルに描画します
 */
const SessionCardMain: React.FC<SesscionCardProps> = ({
  title,
  speaker,
  picture,
  link,
  video,
  videoTitle,
  onClick,
}) => {
  const c = useStyles();
  const content = (
    <React.Fragment>
      <Grid container direction="column" style={{ height: "100%" }}>
        <Grid item>
          <Box mt={3} className={c.topContent}>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item style={{ marginRight: 16 }}>
                <Avatar src={picture} style={{ width: 36, height: 36 }} />
              </Grid>
              <Grid item>
                <Headline color="secondary">{speaker}</Headline>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Box mt={2}>
            <Headline className={`${c.title} ${c.topContent}`}>
              {title}
            </Headline>
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2} mx={1}>
            <AssetLink type="video" url={video} title={videoTitle} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <Paper className={c.frame}>
      {link ? (
        <Link className={c.link} to={link}>
          <div style={{ height: "100%" }} onClick={onClick}>
            {content}
          </div>
        </Link>
      ) : (
        content
      )}
    </Paper>
  );
};

const useAssetLinkStyles = makeStyles(({ spacing }) => ({
  root: {
    minWidth: spacing(16),
  },
  buttonIcon: {
    "& > *:first-child": {
      fontSize: 32,
    },
  },
}));

export const AssetLink: React.FC<{
  url?: string;
  type: "video";
  title?: string;
}> = ({ url, title }) => {
  const c = useAssetLinkStyles();
  return (
    <Button
      color="secondary"
      size="large"
      startIcon={<YouTubeIcon />}
      classes={{ root: c.root, iconSizeLarge: c.buttonIcon }}
      component={url ? ExternalLink : "div"}
      href={url}
      style={{ visibility: url ? "visible" : "hidden" }}
    >
      <Caption>{title ?? "YouTube"}</Caption>
    </Button>
  );
};

export const AndroidSelectsCard: React.FC = () => {
  const c = useStyles();
  return (
    <Paper className={c.frame}>
      <Grid
        container
        direction="column"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Box mt={3} mx={2}>
            <Headline align="center" color="secondary">
              Android Developers Selects
            </Headline>
          </Box>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Box mt={2} ml={1} mr={2}>
            <Headline className={c.title}>
              <ul style={{ margin: 0, lineHeight: "1.5" }}>
                <li> 2020 Android security update</li>
                <li> Securing your app for work</li>
                <li> What&apos;s new in Play Commerce</li>
              </ul>
            </Headline>
          </Box>
        </Grid>
        <Grid item>
          <Box mb={2} mx={1}>
            <AssetLink
              type="video"
              url="https://www.youtube.com/watch?v=bsdtRZusbpI&list=PLaOdaBFokChzx6lXOcc6KOqzjIOLgEI94&index=5"
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SessionCardMain;
