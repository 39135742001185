import React from "react";
import { Paper, useMediaQuery, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        borderRadius: 0,
      },
    },
    smallCard: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },
  }),
  { name: "SectionStyle" }
);
export const Section: React.FC = (props) => {
  const classes = useStyles();
  const large = !useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  return (
    <Paper
      classes={{ root: `${classes.root} ${classes.smallCard}` }}
      elevation={large ? 0 : 1}
      {...props}
    />
  );
};

export const TransparentSection: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
