import React, { ReactElement } from "react";
import { makeStyles, Paper, Box, Grid } from "@material-ui/core";
import Link from "helpers/Link";
import { H5En, LargeBody } from "helpers/text";
import { AssetLink } from "./SessionCard";

// ----------------------------------------------------------------
//     タイムテーブル/カード内
// ----------------------------------------------------------------

const useStyles = makeStyles(
  ({ palette, breakpoints, typography, spacing }) => ({
    frame: {
      height: "100%",
      overflow: "hidden",
      [breakpoints.down("xs")]: {
        paddingBottom: spacing(1),
      },
      "&.timetable-lang": {
        borderTopLeftRadius: "8px",
      },
    },

    link: {
      display: "block",
      height: "100%",
    },

    topContent: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
    },

    header: {
      marginLeft: 0,
      lineHeight: "20px",
      textAlign: "right",
      [breakpoints.down("xs")]: {
        marginLeft: "0px",
        textAlign: "left",
      },
    },

    duration: {
      color: palette.text.secondary,
      verticalAlign: "top",
      fontSize: typography.caption.fontSize,
      [breakpoints.down("xs")]: {
        textAlign: "left",
        paddingLeft: "8px",
      },
    },

    title: {
      [breakpoints.down("xs")]: {
        // fontSize: ${props => props.theme.fonts.normal};
      },

      "& a": {
        color: "inherit",
      },
    },

    message: {
      marginTop: "0.25em",
      marginBottom: "0.25em",
    },
  })
);

// ----------------------------------------------------------------
//     レイアウト/タイムテーブルの項目
// ----------------------------------------------------------------

type SesscionCardProps = {
  title: string | ReactElement;
  speaker?: string;
  picture?: string;
  link?: string;
  onClick?: () => void;
};

/**
 * セッションをテーブルに描画します
 */
const SessionCard2: React.FC<SesscionCardProps> = ({
  title,
  speaker,
  picture,
  link,
  onClick,
}) => {
  const c = useStyles();
  const content = (
    <React.Fragment>
      <Box mt={4} mx={2}>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <img
              src={picture}
              style={{ width: 56, height: 56, marginRight: 16 }}
            />
          </Grid>
          <Grid item>
            <H5En>{speaker}</H5En>
          </Grid>
          <Grid item style={{ width: 32 }} />
        </Grid>
      </Box>
      <Box mt={4} mb={4}>
        <LargeBody className={`${c.title} ${c.topContent}`}>{title}</LargeBody>
      </Box>
      <Box mb={2} mx={1} textAlign="center">
        <AssetLink
          type="video"
          url="https://www.youtube.com/watch?v=9XX4MYKWM0E&list=PLaOdaBFokChwXJ608fBHVdHqNwnLOMoe6&index=6&t=35s"
          title="Day 1"
        />
        <AssetLink
          type="video"
          url="https://www.youtube.com/watch?v=uQrzy2d7y7s&list=PLaOdaBFokChzx6lXOcc6KOqzjIOLgEI94&index=9&t=50s"
          title="Day 2"
        />
      </Box>
    </React.Fragment>
  );

  return (
    <Paper className={c.frame}>
      {link ? (
        <Link className={c.link} to={link}>
          <div style={{ height: "100%" }} onClick={onClick}>
            {content}
          </div>
        </Link>
      ) : (
        content
      )}
    </Paper>
  );
};

export default SessionCard2;
