import React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import visualImageSP from "../../../images/top/vi_sp.svg";
import visualImagePC from "../../../images/top/vi_pc.svg";
import logoSP from "../../../images/top/logo_sp.svg";
import logoPC from "../../../images/top/logo_pc.svg";
import titleSP from "../../../images/top/title_sp.svg";
import titleSPDark from "../../../images/top/title_sp_dark.svg";
import titlePC from "../../../images/top/title_pc.svg";
import titlePCDark from "../../../images/top/title_pc_dark.svg";
import dateSP from "../../../images/top/date_sp.svg";
import dateSPDark from "../../../images/top/date_sp_dark.svg";
import datePC from "../../../images/top/date_pc.svg";
import datePCDark from "../../../images/top/date_pc_dark.svg";
import MainContainer from "helpers/MainContainer";
import { useDarkMode } from "helpers/mode";

const useTopSectionStyle = makeStyles((theme) => ({
  root: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    backgroundImage: `url(${visualImagePC})`,
    height: 574,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${visualImageSP})`,
      height: 300,
      position: "relative",
    },
  },
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${logoPC})`,
    height: 56,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${logoSP})`,
      height: 32,
    },
  },
  title: {
    backgroundRepeat: "no-repeat",
    height: 80,
    [theme.breakpoints.down("xs")]: {
      height: 57,
    },
    "body.helpers-initial-mode-dark &, &.dark": {
      backgroundImage: `url(${titlePCDark})`,
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${titleSPDark})`,
      },
    },
    "body.helpers-initial-mode-light &, &.light": {
      backgroundImage: `url(${titlePC})`,
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${titleSP})`,
      },
    },
  },
  date: {
    backgroundRepeat: "no-repeat",
    height: 56,
    backgroundImage: `url(${datePC})`,
    [theme.breakpoints.down("xs")]: {
      heigth: 37,
      backgroundImage: `url(${dateSP})`,
    },
    "body.helpers-initial-mode-dark &, &.dark": {
      backgroundImage: `url(${datePCDark})`,
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${dateSPDark})`,
      },
    },
    "body.helpers-initial-mode-light &, &.light": {
      backgroundImage: `url(${datePC})`,
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${dateSP})`,
      },
    },
  },
}));

const TopSection: React.FC = () => {
  const [dark] = useDarkMode();
  const darkModeClass = dark ? "dark" : "light";
  const classes = useTopSectionStyle();

  return (
    <Paper square elevation={0} className={`${classes.root}`}>
      <MainContainer>
        <Box pt={{ xs: 8, sm: 12 }}>
          <Box className={classes.logo} />
          <Box
            className={`${classes.title} ${darkModeClass}`}
            mt={{ xs: 2.5, sm: 4 }}
          />
          <Box
            className={`${classes.date} ${darkModeClass}`}
            mt={{ xs: 4.5, sm: 8 }}
          />
        </Box>
      </MainContainer>
    </Paper>
  );
};

export default TopSection;
