type Category =
  | "jetpack"
  | "architecture"
  | "uiux"
  | "others"
  | "crossPlatform";
interface Session {
  // description: string;
  speakers: {
    fullName: string;
    profilePicture?: string;
    tagLine?: string;
    bio?: string;
  }[];
  title: {
    en: string;
    ja: string;
  };
  asset: {
    videoUrl?: string;
  };
  // endsAt: string;
  // startsAt: string;
  language: "ja" | "en";
  sessionCategoryItem?: Category;
}

export const sessions: Session[] = [
  {
    title: {
      ja:
        "アプリのアップデート浸透率を上げろ！ ~in-app updatesを実戦投入して見えてきたもの~",
      en:
        "Let's improve the update rate! - see findings through in-app updates on production",
    },
    speakers: [
      {
        bio:
          "日焼けがすごいけどれっきとしたAndroidエンジニアです。\r\n\r\n- Twitter: https://twitter.com/masaibar",
        tagLine: "株式会社グロービス",
        profilePicture:
          "https://sessionize.com/image?f=a3ce89ec16238186ad3ffd42a45c7861,400,400,1,0,ad9b39ad-b73c-45b5-b18b-eb5837071a5f.jpg",
        fullName: "masaibar",
      },
    ],
    language: "ja",
    sessionCategoryItem: "jetpack",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=u-zUIIe8IfA&list=PLaOdaBFokChwXJ608fBHVdHqNwnLOMoe6&index=2",
    },
  },
  {
    title: {
      ja: "MDCの内部実装から学ぶ 表現力の高いViewの作り方",
      en:
        "How to build expressive Views learning through internal implementations of MDC",
    },
    speakers: [
      {
        bio:
          "DroidKaigi2017 エンジニアが武器にするMaterial Design\r\nDroidKaigi2018 詳解 ViewGroupのレイアウト内部実装",
        tagLine: "人間とAndroidのハーフ",
        profilePicture:
          "https://sessionize.com/image?f=90df0c25025d26ff4983bb69943c3506,400,400,1,0,f2d8e77a-1c9f-45d2-af5a-78ca09c2f106.jpg",
        fullName: "HiroYUKI Seto",
      },
    ],
    language: "ja",
    sessionCategoryItem: "jetpack",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=cReIMUxMxs4&list=PLaOdaBFokChwXJ608fBHVdHqNwnLOMoe6&index=3",
    },
  },
  {
    title: {
      ja: "自動生成でさくさく実装するユニットテスト",
      en: "Implement unit testing quickly by automatic generation",
    },
    speakers: [
      {
        bio:
          "DeNAのSWETチームに所属しています。\r\nAndroidとテストが好きです。",
        tagLine: "DeNA, SWET",
        profilePicture:
          "https://sessionize.com/image?f=9be0343917ac49481a84035be22cbbe0,400,400,1,0,e5-c9cb-49e8-80c4-a0ee63b82f7b.d6fd115e-5bf7-4477-af67-b968cb4ca7ad.jpg",
        fullName: "Nozomi Takuma",
      },
    ],
    language: "ja",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=lj6IMuRcVHY&list=PLaOdaBFokChwXJ608fBHVdHqNwnLOMoe6&index=4",
    },
  },

  {
    title: {
      ja: "Jetpack時代のFragment再入門",
      en: "A guide to learning Fragment on Jetpack era",
    },
    speakers: [
      {
        bio: "KotlinとElixirを書いています。Android Oneが好きです。",
        tagLine: "Software Engineer",
        profilePicture:
          "https://sessionize.com/image?f=e3a27202bb035cadc0124a83aeeeacea,400,400,1,0,43-1596-40a5-b20f-5da1a0f0545f.6cb972b8-a567-4f5d-9e06-7d5eb5bea1a0.jpg",
        fullName: "rnakano",
      },
    ],
    language: "ja",
    sessionCategoryItem: "jetpack",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=7qnvPc1qhf0&list=PLaOdaBFokChwXJ608fBHVdHqNwnLOMoe6&index=5",
    },
  },
  {
    title: {
      ja: "Androidで音声合成(TTS)をフル活用するための知識と実践事例",
      en:
        "The knowledge and practices to make the best use of TTS on Android apps",
    },
    speakers: [
      {
        bio:
          "グロービス でアプリエンジニアをしています。\r\n学生時代から音声系のサービスを開発・運用していました。",
        tagLine: "グロービス ,アプリエンジニア,VoiceUI,音声アシスタント",
        profilePicture: undefined,
        fullName: "maKunugi",
      },
    ],
    language: "ja",
    sessionCategoryItem: "others",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=yIbqlAM080s&list=PLaOdaBFokChzx6lXOcc6KOqzjIOLgEI94&index=2",
    },
  },
  {
    title: {
      ja: "Scadeを使って「Swift」で始めるAndroidアプリ開発",
      en: "Get started Android app development with *Swift* and Scade",
    },
    speakers: [
      {
        bio:
          "iOSエンジニア。\r\nエンジニアとしてWeb, Android, iOSの開発に従事。\r\n現在はiOSをメイン開発している。\r\n\r\niOS Developer.\r\nEngaged in web, Android and iOS development as an engineer.\r\nCurrently, iOS is the main development.\r\n\r\n\r\n",
        tagLine: "every, inc. / iOS Developer",
        profilePicture:
          "https://sessionize.com/image?f=cd34196ac8d2db33e13a28d2ef672879,400,400,1,0,e0-81b7-466b-9116-a3f7a64d66d6.ea00c28e-fce7-475f-9b13-a43285c2ae77.jpg",
        fullName: "Hiromu Tsuruta",
      },
    ],
    language: "ja",
    sessionCategoryItem: "crossPlatform",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=cUzA4YThK7s&list=PLaOdaBFokChzx6lXOcc6KOqzjIOLgEI94&index=3",
    },
  },
  {
    title: {
      ja:
        "KotlinのDelegated Propertiesを活用してAndroidアプリ開発をもっと便利にする",
      en:
        "Make Android apps development more convenient with Kotlin's Delegated Properties",
    },
    speakers: [
      {
        bio:
          "Android engineer @DeNA Co., Ltd.\r\nAuthor of Kotpref (https://github.com/chibatching/Kotpref).",
        tagLine: "DeNA Co., Ltd",
        profilePicture:
          "https://sessionize.com/image?f=3c2fe49183d0e0af15732b8b50f1e14c,400,400,1,0,da-acd4-421a-84db-cfda44d176d4.332e3d28-df85-41ee-bbc4-062974efdeee.png",
        fullName: "chibatching",
      },
    ],
    language: "ja",
    asset: {
      videoUrl:
        "https://www.youtube.com/watch?v=G5x1WUMj1gE&list=PLaOdaBFokChzx6lXOcc6KOqzjIOLgEI94&index=4",
    },
  },
];
